body {
  margin: 0;
  font-family: futura-pt, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: futura-pt-condensed, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.6em;
}

html,
body {
  cursor: url('assets/cursor2.png'), auto;
  
}

a, button, li, ul > li, #icon{
  cursor: url('assets/bigCursor.png'), none;
}
